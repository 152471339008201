/* eslint-disable */
import { Options, Vue } from 'vue-class-component';
import { Action, Getter, Mutation, State } from 'vuex-class'
import { SexMasterClass } from '@/store/sexMasterClass/types';

const namespace: string = 'sexMasterClass';

export default class SexMasterClassesModelChoicePage extends Vue {
	@State('sexMasterClass') sexMasterClass: SexMasterClass | undefined;
	@Action('getAllModels', {namespace}) getAllModels: any;
	@Getter('getModels', {namespace}) getModels: any;

	created() {
		this.getAllModels();
	}
}
